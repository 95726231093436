<template>
  <form-history-layout
    type="coupon"
  >
    <template v-slot:formLayout>
      <v-form ref="form">
        <s-label-text
          v-model="code"
          background-color="#236BB5"
          class="my-2"
          :label_text="$t('coupon.code')"
          :rules="rules.code"
          color="white"
          outlined
          dense
          dark
          input
        />
        <div class="d-flex justify-end">
          <v-btn @click="submit" :loading="isLoading">{{ $t('coupon.send') }}</v-btn>
        </div>
      </v-form>
    </template>

    <template v-slot:table>
      <thead back>
        <tr>
          <th class="text-left">{{ $t('coupon.id') }}</th>
          <th class="text-left">{{ $t('coupon.name') }}</th>
          <th class="text-left">{{ $t('coupon.comment') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in list" :key="(item.id || Math.random()) +''+ new Date()"
        >
          <td>{{ item.id || 'คิว' }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.comment!==''? item.comment : 'กำลังรอดำเนินการ' }}</td>
        </tr>
      </tbody>
    </template>
  </form-history-layout>
</template>

<script>
import validate from '../../helper/validates.js'
import FormHistoryLayout from '../../layouts/sub_layouts/FormHistoryLayout.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    FormHistoryLayout,
  },
  data() {
    return {
      isLoading: false,
      code: '',
      rules: {
        code: [validate.required()],
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapActions('coupon', ['useCoupon']),
    async submit() {
      if(this.$refs.form.validate()) {
        this.isLoading = true
        await this.useCoupon(this.code)
        this.code = ''
        this.isLoading = false
      }
    }
  },
  computed: {
    ...mapGetters('coupon', ['coupons', 'couponQueues']),
    list () {
      return [
        ...this.couponQueues,
        ...this.coupons.filter(coupon => coupon).sort(( a, b ) =>{
          if ( a.id > b.id ){
            return -1;
          }
          return 1;
        })
      ]
    }
  }
}
</script>

<style>

</style>
